// Attention, si on veut retirer le slash de fin il y a des url à modifier exemple `${Urls.APP_TESTS_CODE_DE_LA_ROUTE}${this.props.id}/resultats`
export const Urls = {
    HOME: "/",
    APP_DASHBOARD: "/app/dashboard/",
    APP_MES_COURS: "/app/mes-cours/",
    APP_MES_COURS_LISTE: "/app/mes-cours/cours/",
    APP_MES_FICHES: "/app/mes-fiches/",
    APP_MON_COMPTE: "/app/mon-compte/",
    APP_INSCRIPTION: "/app/inscription/",
    APP_CONNEXION: "/app/connexion/",
    APP_DEVENIR_PREMIUM_SUCCESS: "/app/devenir-premium/success/",
    APP_DEVENIR_PREMIUM_CANCEL: "/app/devenir-premium/cancel/",
    APP_QCM_V2: "/app/cours-de-code/:uri_theme/:uri_cours/qcm/",
    APP_QCM: "/app/cours-de-code/:uri_cours/qcm/",
    APP_CONGRATULATIONS_SIGNUP: "/app/bienvenue-chez-code-en-poche/",
    BASE_APP_QUIZ: "/app/tests-code-de-la-route/",
    APP_QUIZ: "/app/tests-code-de-la-route/:id/",
    APP_RESULTATS: "/app/tests-code-de-la-route/:id/resultats/",
    APP_CORRECTION: "/app/tests-code-de-la-route/:id/resultats/:nb/",
    APP_TESTS_CODE_DE_LA_ROUTE: "/app/tests-code-de-la-route/",
    TESTS_CODE_DE_LA_ROUTE: "/tests-code-de-la-route/",
    COURS_DE_CODE: "/cours-de-code/",
    ARTICLES: "/articles/",
    DEVENIR_PREMIUM: "/devenir-premium/",
    FICHES: "/fiches/",
    PLAN_DU_SITE: "/plan-du-site/",
    CGV: "/cgv/",
    MENTIONS_LEGALES: "/mentions-legales/",
    POLITIQUE_DE_CONFIDENTIALITE: "/politique-de-confidentialite/",
    POLITIQUE_UTILISATION_DES_COOKIES: "/politique-d-utilisation-des-cookies/",
    APPLICATION_CODE_DE_LA_ROUTE: "/application-code-de-la-route/",
    APP_QUIZ_BASEURL: "/app/tests-code-de-la-route/" + process.env.GATSBY_QUIZ_BASEURL + "/:id/",
    APP_RESULTATS_BASEURL: "/app/tests-code-de-la-route/" + process.env.GATSBY_QUIZ_BASEURL + "/:id/resultats/",
    APP_CORRECTION_BASEURL: "/app/tests-code-de-la-route/" + process.env.GATSBY_QUIZ_BASEURL + "/:id/resultats/:nb/",
    POLITIQUE_GESTION_DE_COMPTE: "/politique-de-gestion-de-compte/",
};
Object.freeze(Urls)

import React from 'react'

const TrustBox = ({ templateId, height, theme }) => {
  // Créez une référence qui renvoie à l'élément <div> qui va représenter la TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // Si window.Trustpilot est disponible, cela signifie que nous devons charger la TrustBox depuis notre référence.
    // Si ce n'est pas le cas, cela signifie que le script que vous avez collé dans <head /> n'est pas encore chargé.
    // Quand il sera chargé, la TrustBox sera automatiquement affichée.
    if (window !== "undefined" && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div ref={ref} className="trustpilot-widget" // Cette classe a été renommée « className ».
    data-locale="fr-FR" data-template-id={templateId} data-businessunit-id="627d1060030442d3ad107fb2" data-style-height={height} data-style-width="100%" data-theme={theme}>
    <a href="https://fr.trustpilot.com/review/codeenpoche.fr" target="_blank" rel="noopener"> Trustpilot</a>
    </div>
  );
};
export default TrustBox

import React, { useEffect, useState } from "react";
import { useMixpanel } from 'gatsby-plugin-mixpanel'
import { useLocation } from "@reach/router"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import { cookiesPopupContainer } from './CookiesPopup.module.css'
import { cookiesPopupTopContainer } from './CookiesPopup.module.css'
import { cookiesPopupTopTextContainer } from './CookiesPopup.module.css'
import { cookiesPopupTopTextSubtitle } from './CookiesPopup.module.css'
import { cookiesPopupTopTextTitle } from './CookiesPopup.module.css'
import { cookiesPopupTextDescription } from './CookiesPopup.module.css'
import { cookiesPopupConsentementContainer } from './CookiesPopup.module.css'

import cookies from '../images/cookies.svg'


/**
 * Component that alerts if you click outside of it
 */
export default function CookiesPopup(props) {


    const [consentementCookies, setConsentementCookies] = useState(false);
    const mixpanel = useMixpanel()
    const location = useLocation()

    useEffect(() => {
        try {
            const cookiesConsentementStr = getCookie('gatsby-gdpr-google-analytics-consent')
            setConsentementCookies(JSON.parse(cookiesConsentementStr))

            if (JSON.parse(cookiesConsentementStr) && !mixpanel.has_opted_in_tracking()){ // Si l'utilisateur a déjà un cookie autorisant les statistiques et qu'il n'est pas déjà tagué en optin, on l'optin à Mixpanel qui est opt-out par défaut dans les option du gatsby-config
            // Mixpanel optin
            mixpanel.opt_in_tracking();
            }
        } catch (err) {
        }

    }, [])

    // L'utilisateur a donné son consentement
    const addConsentToCookies = () => {
        try {
            setCookie('gatsby-gdpr-google-analytics-consent', true, 395);
            setCookie('gatsby-gdpr-hotjar', true, 395);
            // GA opt-in
            initializeAndTrack(location)
            // Mixpanel optin
            mixpanel.opt_in_tracking();
            // Fermeture de la popup
            setConsentementCookies(true)
        } catch {

        }
    }

    // Close the cookie popup
    const closeCookiesPopup = () => {
        // Ajout du cookie de consentement
        setCookie('gatsby-gdpr-google-analytics-consent', "false", 395); // Si on ne met pas false sous forme de string, il ne set pas de valeur au cookie
        setCookie('gatsby-gdpr-hotjar', "false", 395);
        setConsentementCookies(false)
    }

    const texte_cookies = "Code en poche utilise des cookies pour stocker et/ou accéder à des informations sur votre appareil.\n\nNous n'utilisons aucun cookie à des fins publicitaires mais uniquement des cookies à des fins statistiques. Une donnée qui nous est indispensable pour mieux comprendre nos statistiques de visites.\n\nPour en savoir plus, consultez notre politique d'utilisation des cookies.\n\n"

    return (
        <div className={cookiesPopupContainer} style={{ display: consentementCookies === true || consentementCookies === false ? "none" : "flex" }}>
            <div className={cookiesPopupTopContainer}>
                <img src={cookies} alt="cookies" />
                <div className={cookiesPopupTopTextContainer}>
                    <p id={cookiesPopupTopTextSubtitle}>Un, deux, trois…</p>
                    <p id={cookiesPopupTopTextTitle}>Cookies !</p>
                </div>
            </div>
            <p id={cookiesPopupTextDescription}>{texte_cookies}</p>
            <div className={cookiesPopupConsentementContainer}>
                <a onClick={closeCookiesPopup}>Refuser</a>
                <button onClick={addConsentToCookies}>Accepter</button>
            </div>
        </div>
    );
}


function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
import axios from 'axios';
import {SecretEmail} from 'src/utils/Utils'

export default class AuthService {
    // Initializing important variables
    constructor() {
        this.domain = process.env.GATSBY_API_DOMAIN
        this.getProfile = this.getProfile.bind(this);
    }


    /**
   * Signin function (email, password)
   */

    signin = (email, password) => {
        let formData = new FormData();
        formData.append('email', email)
        formData.append('password', password)

        return axios({
            method: 'post',
            url: this.domain + '/api/SigninCEPS.php',
            data: formData,
            headers: { 'X-API-Key': process.env.GATSBY_SECRET_API_KEY },
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then((response) => {
                // Signin success
                let message = "Vous êtes maintenant connecté";
                if (typeof localStorage !== `undefined`) {
                    localStorage.setItem('jwt', response.data.jwt);
                    localStorage.setItem('refresh_token', response.data.refreshToken)
                }
                return [true, message]; // [signin success, message]
            })
            .catch((error) => {
                //handle error
                if (error.response) {
                    if (error.response.status === 403) {
                        let message = "Email ou mot de passe incorrect";
                        return [false, message, error.response.status];
                    } else if (error.response.status === 401) {
                        let message = "Avant de pouvoir vous connecter, veuillez d'abord activer votre compte en cliquant sur le lien qui vous a été envoyé par email lors de votre inscription.";
                        return [false, message, error.response.status];
                    }
                }
                let message = "Erreur lors de la connexion, veuillez réessayer.";
                return [false, message];
            });

    }

    /**
     * Signup function and send email (email, password, name, firstname)
     */
    signup = (email, password, name, firstname, agreement) => {
        let formData = new FormData();
        formData.append('email', email)
        formData.append('password', password)
        formData.append('name', name)
        formData.append('firstname', firstname)
        formData.append('agreement', agreement)

        return axios({
            method: 'post',
            url: this.domain + '/api/SignupCEPS.php',
            data: formData,
            headers: { 'X-API-Key': process.env.GATSBY_SECRET_API_KEY },
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then((response) => {
                //handle success
                //let message = "Félicitations votre compte " + email + " a bien été créé. Pour activer votre compte, veuillez cliquer sur le lien d'activation qui vient de vous être envoyé par email."
                return [true];
            })
            .catch((error) => {
                //handle error
                let message = ""
                if ((error.response.data).includes("Duplicate")) {
                    message = "Impossible de créer ce compte utilisateur : cette adresse email existe déjà. Vérifiez que vous avez bien activé votre compte en cliquant sur le lien qui vous a été envoyé par email lors de votre inscription."
                    return [false, message, 401];
                }
                else {
                    message = "Impossible de créer ce compte utilisateur. Veuillez réessayer."
                    return [false, message, 500];
                }

            });
    };

    /**
     *  Signout function
     */
    signout() {
        // Clear user token and profile data from localStorage and into database
        const refresh_token = this.getRefreshToken();
        if (!refresh_token)
            return

        if (typeof localStorage !== `undefined`) {
            localStorage.removeItem('jwt');
            localStorage.removeItem('refresh_token');
        }
        this.signoutRefreshToken(refresh_token).then((response) => {
            window.location.reload();
        })
            .catch((error) => {
                window.location.reload();
            });



    }

    /**
     * Update user information from /mon-compte (id, email, firstname, name, password, passwordNew)
     */
    changeUserInfo = (id, email, firstname, name, password, passwordNew) => {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('email', email);
        formData.append('firstname', firstname);
        formData.append('name', name);
        formData.append('password', password);
        formData.append('passwordNew', passwordNew);

        const token = this.getToken();

        return axios({
            method: 'post',
            url: this.domain + '/api/UpdateUserAccountCEPS.php',
            data: formData,
            headers: { 'X-API-Key': process.env.GATSBY_SECRET_API_KEY, 'Authorization': "Bearer " + token },
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then((response) => {
                if (typeof localStorage !== `undefined`) {
                    localStorage.setItem('jwt', response.data.jwt);
                    if (response.data.refreshToken) // If the refreshToken is null, the user didn't change his password and can keep his actual refresh token
                        localStorage.setItem('refresh_token', response.data.refreshToken);
                }
                let message = "Les informations ont bien été mises à jour.";
                return [true, message];
            })
            .catch((error) => {
                let message = ""
                if ((error.response.status) === 401)
                    message = "Le mot de passe actuel est incorrect."
                else if ((error.response.status) === 400)
                    message = "Impossible de mettre à jour les informations, vérifiez le format du prénom, du nom et du nouveau mot de passe."
                else
                    message = "Impossible de mettre à jour les informations, veuillez réessayer."
                return [false, message];
            });
    }

    /**
    * Create hash into database and send email to user for changing password
    */
    forgetPassword = (email) => {
        let formData = new FormData();
        formData.append('email', email);

        return axios({
            method: 'post',
            url: this.domain + '/api/ForgetPasswordCEPS.php',
            data: formData,
            headers: { 'X-API-Key': process.env.GATSBY_SECRET_API_KEY },
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then((response) => {
                //handle success
                let message = "Un email vous a été envoyé à " + email + ". Veuillez cliquer sur le lien reçu pour réinitialiser votre mot de passe.";
                return [true, message];
            })
            .catch((error) => {
                //handle error
                let message = "";
                if (error.response.status === 400) {
                    message = "Réinitialisation du mot de passe impossible. Vérifiez que cette adresse email est bien associée à un compte Code en Poche.";
                } else if (error.response.status === 401) {
                    message = "Veuillez d'abord activer votre compte en cliquant sur le lien qui vous a été envoyé par email lors de votre inscription.";
                } else {
                    message = "Code d'erreur : " + error.response.status + ". Si le problème persiste, envoyez nous un email à " + SecretEmail() + " en précisant le code d'erreur. Nous ferons tout notre possible pour vous aider :)";
                }
                return [false, message, error.response.status];
            });

    }

    /**
    * Resend the verification email if the user doesn't received it
    */
    resendVerificationEmail = (email) => {
        let formData = new FormData();
        formData.append('email', email);

        return axios({
            method: 'post',
            url: this.domain + '/api/ResendVerificationEmailCEPS.php',
            data: formData,
            headers: { 'X-API-Key': process.env.GATSBY_SECRET_API_KEY },
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then((response) => {
                //handle success
                let message = "Un email vous a été envoyé à " + email + ". Veuillez cliquer sur le lien reçu pour activer votre compte.";
                return [true, message];
            })
            .catch((error) => {
                //handle error
                let message = "";
                if (error.response.status === 409) {
                    message = "Envoi de l'email impossible : cette adresse email n'est associée à aucun compte Code en Poche. Veuillez vous créer un compte.";
                } else if (error.response.status === 401) {
                    message = "Envoi de l'email impossible : ce compte a déjà été activé. Vous pouvez dès à présent vous connecter avec votre identifiant et votre mot de passe.";
                } else {
                    message = "Code d'erreur : " + error.response.status + ". Si le problème persiste, envoyez nous un email à " + SecretEmail() + " en précisant le code d'erreur. Nous ferons tout notre possible pour vous aider :)";
                }
                return [false, message];
            });
    }

    /**
    * Singout the user by removing the refresh_token in database
    */
    signoutRefreshToken = (refresh_token) => {
        let formData = new FormData();
        formData.append('refresh_token', refresh_token);

        return axios({
            method: 'post',
            url: this.domain + '/api/SignoutRefreshTokenCEPS.php',
            data: formData,
            headers: { 'X-API-Key': process.env.GATSBY_SECRET_API_KEY },
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then((response) => {
                //handle success
            })
            .catch((error) => {
                //handle error
            });

    }

    /**
     * Check if the jwt token inside localstorage is valid. Check if the token is valid on server side (specifically if the token signature is the same that the payload -payload not modified-)
     */
    isJwtValide = () => {
        const token = this.getToken();

        const firstname = this.getProfile() ? this.getProfile().data.firstname : null;
        const email = this.getProfile() ? this.getProfile().data.email : null;

        return axios({
            method: 'post',
            url: this.domain + '/api/IsJwtValidCEPS.php',
            headers: { 'X-API-Key': process.env.GATSBY_SECRET_API_KEY, 'Authorization': "Bearer " + token },
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then((response) => {
                //handle success

                // Set the user username and email to Crisp, to be able to recognize the user in the Crisp console messenger
               /* if (typeof window !== undefined){          
                    $crisp.push(["set", "user:email", [email]]);
                    $crisp.push(["set", "user:nickname", [firstname]]);
                }*/
                return true;
            })
            .catch((error) => {
                //handle error
                return false;
            });

    }

    /**
     * Checks if there is a saved token and it's still valid, check also on server side for JWT validity
     */
    isLoggedIn() {
        const token = this.getToken(); // Getting token from localtorage

        if ((!!token && this.isTokenExpiredSoon(token))) { // If the token is expired or will expire soon => Renew
            return this.renewJwtWithRefreshToken().then((response) => {
                return response;
            })
        } else if (!!token && !this.isTokenExpiredSoon(token)) { // If the token is not expired => isJwtValide ?
            return this.isJwtValide().then((response) => {
                return response;
            })
        } else
            return Promise.resolve(false); // The token doesn't exist
    }

    /**
     * Renew jwt token (if the user become premium for exemple)
     */
    renewJwtWithRefreshToken = () => {
        const refresh_token = this.getRefreshToken();

        let formData = new FormData();
        formData.append('refresh_token', refresh_token);

        return axios({
            method: 'post',
            url: this.domain + '/api/RenewJwtWithRefreshTokenCEPS.php',
            data: formData,
            headers: { 'X-API-Key': process.env.GATSBY_SECRET_API_KEY },
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then((response) => {
                //handle success
                if (typeof localStorage !== `undefined`)
                    localStorage.setItem('jwt', response.data.jwt); // The new JWT token is set into session storage
                return true;
            })
            .catch((error) => {
                //handle error
                return false;
            });

    }

    /**
     * Return the decoded Jwt token
     */
    getProfile() {
        // decode the token
        if (this.getToken())
            return this.parseJwt(this.getToken());
        else
            return null;
    }

    /**
     * Get the Jwt token from loacalstorage
     */
    getToken() {
        // Retrieves the user token from localstorage
        if (typeof localStorage !== `undefined`)
            return localStorage.getItem('jwt')
        else
            return null;
    }

    /**
   * Get the Jwt token from loacalstorage
   */
    getRefreshToken() {
        // Retrieves the user token from localstorage
        if (typeof localStorage !== `undefined`)
            return localStorage.getItem('refresh_token')
        else
            return null;
    }


    /**
   * Check if the token in parameter will expire soon
   * @param {*} token 
   */
    isTokenExpiredSoon(token) {
        try {
            const decoded = this.parseJwt(token);

            if (decoded.exp < ((Date.now() / 1000) + (60 * 60))) { // Checking if token will expire in 60 minutes or if it is already expired
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    /**
     * Parse the Jwt token
     * @param {*} token 
     */
    parseJwt(token) {
        try {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch {
            if (typeof localStorage !== `undefined`) {
                localStorage.removeItem('jwt');
                localStorage.removeItem('refresh_token');
            }
            return null;
        }
    };

}
import React, { useState, useEffect } from 'react'
import AuthService from '../authentication/AuthService'
import { Link, navigate } from "gatsby"
import logo_code_en_poche from "../images/code-en-poche_picto.png";
import './menu.css';
import { Urls } from "src/utils/UrlsConst"
import { decode, encode } from 'base-64'
import ic_premium from "src/images/crown.svg";

// Le menu du site web
function Menu(props) {
  const Auth = new AuthService()

  const handleSignout = () => {
    Auth.signout();
  }

let base64data_devenir_premium = encode(Urls.DEVENIR_PREMIUM)
let base64data_connexion = encode(Urls.APP_CONNEXION)



  const showMenu = () => {
    // Only for mobile
    if (typeof window !== `undefined` && window.matchMedia("(max-width: 812px)")) {
      // Les try catch sont très importants car getElementById("drop-down-menu-id") existe en fonction de si on est connecté ou non
      try {
        var x = document.getElementById("menu-middle");

        if (x.className === "menu" || x.className === "menu is-active") { // Le menu ne s'affiche pas
          x.className = "menu responsive";
        }
        else { // Le menu s'affiche
          x.className = "menu is-active";
        }
      }
      catch { }

      try {
        var x = document.getElementById("drop-down-menu-id");
        if (x.className === "drop-down-menu") {
          x.className += " responsive";
        } else {
          x.className = "drop-down-menu";
        }
      } catch { }
    }
  }

  const [isLog, setIsLog] = useState(null);
  const user = Auth.getProfile();
  //const ismobile = isMobile();

  useEffect(() => {
    let isMounted = true;
    Auth.isLoggedIn().then((response) => {
      if (isMounted)
        setIsLog(response);
    });

    //Add event listener to obfuscate links
      try {
        var elementText = document.getElementsByClassName("obflink_menu")

        for (let i = 0; i < elementText.length; i++) { // We have to add an event listener on each breadcrumb elements in order to catch the click
          let urlBase64 = elementText[i].getAttribute("data-o")
          let urlUtf8 = decode(urlBase64)

          if (elementText[i].getAttribute('listener') !== 'true') {
            elementText[i].setAttribute('listener', 'true');
  
            elementText[i].addEventListener('click', function () {
              openUrl(urlUtf8);
            }, false);
          }
        }

        function openUrl(url) {
          navigate(url)
        };
      } catch {

      }
    

    return () => { isMounted = false };
  });

  // Firstname Box
  let userbox =
  <>{isLog ? <li><div className="drop-down-menu responsive" id="drop-down-menu-id">
      <div className="username-box">
        <b>{isLog && user && user.data.premium ? <img src={ic_premium} alt="" id="icone-premium"/> : null}{Auth.getProfile().data.firstname}</b>
      </div>
      <ul className="drop-down-menu-content">
        <Link onClick={showMenu} to={Urls.APP_MON_COMPTE} className="nav-link-dropdown-menu">Mon compte</Link>
        <a onClick={() => { handleSignout(); showMenu(); }} className="nav-link-dropdown-menu">Déconnexion</a>
      </ul>
    </div></li> : null}</>

  let menu = <div></div>;

  // Menu if the user is not login
  if (!isLog) {
    menu =
      <ul>
        <li><Link onClick={showMenu} to={Urls.COURS_DE_CODE} className="nav-link" activeClassName="active" partiallyActive={true}>Cours</Link></li>
        <li><Link onClick={showMenu} to={Urls.TESTS_CODE_DE_LA_ROUTE} className="nav-link" activeClassName="active" partiallyActive={true}>Tests de code</Link></li>
        <li><Link onClick={showMenu} to={Urls.ARTICLES} className="nav-link" activeClassName="active" partiallyActive={true}>Guides</Link></li>
        <li>
        <span onClick={showMenu} data-o={base64data_devenir_premium} className="nav-link obflink_menu" id="connexion-text-purple" activeClassName="active" partiallyActive={true}>
        Formations</span></li>
        {userbox}
        <li>
          <span data-o={base64data_connexion} className="header-cta-box light-orange-background obflink_menu" onClick={() => { showMenu(); }}>
            <b>Me connecter</b>
          </span>
        </li>
      </ul>
  } else if (isLog && user && !user.data.premium) { // Menu if the user is login and not premium
    menu =
      <ul>
        <li><Link onClick={showMenu} to={Urls.COURS_DE_CODE} className="nav-link" activeClassName="active" partiallyActive={true}>Cours</Link></li>
        <li><Link onClick={showMenu} to={Urls.TESTS_CODE_DE_LA_ROUTE} className="nav-link" activeClassName="active" partiallyActive={true}>Tests de code</Link></li>
        <li><Link onClick={showMenu} to={Urls.ARTICLES} className="nav-link" activeClassName="active" partiallyActive={true}>Guides</Link></li>
        <li>
          <span onClick={showMenu} data-o={base64data_devenir_premium} className="header-cta-box purple-background obflink_menu" activeClassName="active" partiallyActive={true}>Formations</span>
        </li>
        {userbox}

      </ul>
  } else { // Menu if the user is login and premium
    menu = <ul>
      <li><Link onClick={showMenu} to={Urls.APP_DASHBOARD} className="nav-link" activeClassName="active" partiallyActive={true}>Dashboard</Link></li>
      <li><Link onClick={showMenu} to={Urls.APP_MES_COURS} className="nav-link" activeClassName="active" partiallyActive={true}>Cours</Link></li>
      <li><Link onClick={showMenu} to={Urls.TESTS_CODE_DE_LA_ROUTE} className="nav-link" activeClassName="active" partiallyActive={true}>Tests de code</Link></li>
      <li><Link onClick={showMenu} to={Urls.APP_MES_FICHES} className="nav-link" activeClassName="active" partiallyActive={true}>Fiches</Link></li>
      <li><Link onClick={showMenu} to={Urls.ARTICLES} className="nav-link" activeClassName="active" partiallyActive={true}>Guides</Link></li>
      {userbox}
    </ul>
  }

  return (

    <header id="app-header-id" className="app-header" style={props.styleBlur}>
      <nav className="navbar" >
        <div className="navbar-container" >
          <div className="navbar-brand" id="navbar-brand-id">
            <div className="navbar-logo">
              <Link className="logo-menu-link" to="/">
                <div className="brand-logo">
                  <img className="logo-menu" src={logo_code_en_poche} alt="Logo Code en Poche" />
                </div>
                <div className="brand-content">
                  <div className="brand-title brand-title-black">
                    Code en poche
                  </div>
                  <div className="brand-slogant brand-slogant-grey">
                    Le code n’a jamais été aussi facile
                  </div>
                </div>
              </Link>
            </div>
            <a onClick={() => { showMenu(); }} id="hamburger">☰</a>
          </div>
          <div className="navbar-menu-container">
            <div className="navbar-menu">

              <div className="menu responsive" id="menu-middle"> 
                {menu}
              </div>

            </div>
          </div>
        </div>
      </nav>
    </header>
  );
  // 3 conditions (true, false and null : if null we don't display anything)
  // className="menu responsive" pour cacher le menu automatiquement sur mobile
}


export default Menu

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from "prop-types"
import Menu from "./menu"
import Footer from "./footer"
import './App.css';
import CookiesPopup from 'src/utils/CookiesPopup.js'
import { IsSourceFromWebview } from "src/utils/Utils"



const Layout = ({ children, pageContext, ...props }) => {

const isSourceFromWebview = IsSourceFromWebview();
  /* Check if we display the header and footer or not */
  var notDisplayMenu = null;
  if (typeof window !== 'undefined' ){
    notDisplayMenu = window.location.pathname.match(/^\/app\/tests-code-de-la-route\/[0-9]*\/(?!\S)/) ||
  window.location.pathname.match(/^\/app\/tests-code-de-la-route\/[0-9]*\/resultats\/[0-9]*\/(?!\S)/) ||
  window.location.pathname.match(/^\/app\/connexion\/(?!\S)/) ||
  window.location.pathname.match(/^\/app\/inscription\/(?!\S)/) ||
  window.location.pathname.match(/^\/app\/bienvenue-chez-code-en-poche\/(?!\S)/) ||
  pageContext.layout === "404" ||
  window.location.pathname.match(/^\/conduite-online-installation/) ||
  window.location.pathname.match(/^\/conduite-online-volant/) ||
  window.location.pathname.match(/^\/app\/tests-code-de-la-route\/[a-zA-Z0-9]*\/[0-9]*\/(?!\S)/) ||
  window.location.pathname.match(/^\/app\/tests-code-de-la-route\/[a-zA-Z0-9]*\/[0-9]*\/resultats\/(?!\S)/) ||
  window.location.pathname.match(/^\/app\/tests-code-de-la-route\/[a-zA-Z0-9]*\/[0-9]*\/resultats\/[0-9]*\/(?!\S)/) ||
  window.location.pathname.match(/^\/extrait-hacker-le-code/)
  } else {
    notDisplayMenu = false;
  }

  if (notDisplayMenu) { // See gatsby-node : handle by gatsby-plugin-layout
    return (<div className="app">

      {isSourceFromWebview ? null : <CookiesPopup />}
      <main className="content">{children}</main>

    </div>)
  } else {

    let isHomePage = false
    if (typeof window !== 'undefined' ){
      isHomePage = window.location.pathname === "/"
    }
    
    return (
      <div className="app">
        {isSourceFromWebview ? null : <CookiesPopup />}
        {isSourceFromWebview ? null : <Menu />}

        <main className="content">{children}</main>

        {isSourceFromWebview ? null : <Footer isHomePage={isHomePage} />}
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
